<template>
  <v-app>
    <v-container class="fill-height grey lighten-4" fluid>
      <v-row align="center" justify="center">
        <v-col cols="12"
               sm="8"
               md="4">
          <v-form @submit.prevent="submit"
                  style="position: relative;"
                  ref="form"
                  lazy-validation>
            <v-card>
              <v-card-title class="primary">
                <v-toolbar-title class="white--text">
                  Login
                </v-toolbar-title>
              </v-card-title>
              <v-card-text class="pt-4 pb-0">
                <v-text-field v-model="dataObject.userName"
                              :rules="notEmptyRules"
                              label="Username"
                              clearable
                              type="text" />
                <v-text-field v-model="dataObject.password"
                              :rules="notEmptyRules"
                              color="black"
                              :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                              @click:append="showPassword = !showPassword"
                              label="Password"
                              clearable
                              :type="showPassword ? 'text' : 'password'" />
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn class="mb-3 mr-2"
                       type="submit"
                       dark
                       color="primary">Login</v-btn>
              </v-card-actions>
            </v-card>
            <v-overlay :value="loading" dark>
              <v-progress-circular indeterminate
                                   :size="50"
                                   color="white" />
            </v-overlay>
          </v-form>
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar v-model="showErr"
                :timeout="5000"
                color="red" min-width="500">
      {{ err }}
      <template v-slot:action="{ attrs }">
        <v-icon v-bind="attrs"
                @click="showErr = false">
          mdi-close-circle-outline
        </v-icon>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { notEmptyRules, emailRules } from "@/services/validationRules";
import token from "@/services/token";
import { mapMutations } from 'vuex';
export default {
  name: "Login",
  data: () => ({
    showPassword: false,
    notEmptyRules,
    emailRules,
    dataObject: {
      userName: '',
      password: ''
    },
    loading: false
  }),
  methods: {
    ...mapMutations({setUser: 'SET_USER'}),

    async submit () {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true;
          const res = await this.$api.post('login', this.dataObject);
          token.saveToken(res.token);
          this.setUser(res.user);
          await this.$router.push('/');
        } catch (err) {
          this.showErr = true;
          this.err = err.response.data.error;
        }
        finally {
          this.loading = false;
        }
      }
    }
  }
}
</script>

<style scoped>

</style>